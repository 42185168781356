import type { UrlObject } from 'url';

import React from 'react';

import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { usePathname } from 'next/navigation';

import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

// NOTE: Add support for the sx prop for consistency with the other branches.
const Anchor = styled('a')({
  textDecoration: 'none',
});

interface NextLinkComposedProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>,
    Omit<
      NextLinkProps,
      'href' | 'as' | 'passHref' | 'onMouseEnter' | 'onClick' | 'onTouchStart'
    > {
  to: NextLinkProps['href'];
  linkAs?: NextLinkProps['as'];
}

const NextLinkComposed = React.forwardRef<
  HTMLAnchorElement,
  NextLinkComposedProps
>(function NextLinkComposed(props, ref) {
  const {
    to,
    linkAs,
    replace,
    scroll,
    shallow,
    prefetch,
    legacyBehavior = true,
    locale,
    ...other
  } = props;

  return (
    <NextLink
      href={to}
      prefetch={prefetch}
      as={linkAs}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      passHref
      locale={locale}
      legacyBehavior={legacyBehavior}
    >
      <Anchor ref={ref} {...other} />
    </NextLink>
  );
});

export type AppLinkProps = {
  activeClassName?: string;
  as?: NextLinkProps['as'];
  href: NextLinkProps['href'] | UrlObject | string;
  linkAs?: NextLinkProps['as']; // NOTE: Useful when the as prop is shallow by styled().
  noLinkStyle?: boolean;
} & Omit<NextLinkComposedProps, 'to' | 'linkAs' | 'href'> &
  Omit<MuiLinkProps, 'href'>;

// NOTE: A styled version of the Next.js Link component:
// https://nextjs.org/docs/api-reference/next/link
export const AppLink = React.forwardRef<HTMLAnchorElement, AppLinkProps>(
  function AppLink(props, ref) {
    const {
      activeClassName = 'active',
      as,
      className: classNameProps,
      href,
      legacyBehavior,
      linkAs: linkAsProp,
      locale,
      noLinkStyle,
      prefetch,
      replace,
      role, // NOTE: Link don't have roles.
      scroll,
      shallow,
      ...commonProps
    } = props;

    const routerPathname = usePathname();
    const pathname = typeof href === 'string' ? href : href?.pathname;
    const className = clsx(classNameProps, {
      [activeClassName]: routerPathname === pathname && activeClassName,
    });

    const isExternal =
      typeof href === 'string' &&
      (href.indexOf('http') === 0 || href.indexOf('mailto:') === 0);

    if (isExternal) {
      if (noLinkStyle) {
        return (
          <Anchor
            className={className}
            href={href}
            ref={ref}
            {...commonProps}
          />
        );
      }

      return (
        <MuiLink className={className} href={href} ref={ref} {...commonProps} />
      );
    }

    const linkAs = linkAsProp || as;
    const nextjsProps = {
      to: href,
      linkAs,
      replace,
      scroll,
      shallow,
      prefetch,
      legacyBehavior,
      locale,
    };

    if (noLinkStyle) {
      return (
        <NextLinkComposed
          className={className}
          ref={ref}
          {...nextjsProps}
          {...commonProps}
        />
      );
    }

    return (
      <MuiLink
        component={NextLinkComposed}
        className={className}
        ref={ref}
        {...nextjsProps}
        {...commonProps}
      />
    );
  },
);
